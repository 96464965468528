import { displayDate, displayDateTime, displayUTCDateTime, displayTime, convertSecondToTime } from "./dateHelper";
import { logout } from "./logOutHelper";
import statusColor from "./statusColor";
import { isAdmin, isEmployee, isApprover, isSuperApprover } from "./userRoleHelper";
import { userProfile } from "./userProfileHelper";

export default {
  filters: {},
  methods: {
    $statusColor: statusColor,
    $displayDate: displayDate,
    $displayDateTime: displayDateTime,
    $displayUTCDateTime: displayUTCDateTime,
    $displayTime: displayTime,
    $convertSecondToTime: convertSecondToTime,
    $logOut: logout,
    $isAdmin: isAdmin,
    $isEmployee: isEmployee,
    $isApprover: isApprover,
    $isSuperApprover: isSuperApprover,
    $userProfile: userProfile
  },
};
