import '@/assets/styles/tailwind.css';
import { createApp } from "vue";
import ElementPlus from "element-plus";
import "element-plus/dist/index.css";
import App from "./App.vue";
import router from "./router";
import * as ElementPlusIconsVue from "@element-plus/icons-vue";
import store from "./store";
import axios from "axios";
import VueAxios from "vue-axios";
import helpers from "./helpers";
import ExportExcelSheetVueJs from "export-excel-sheet-vue-js";

const app = createApp(App);

for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
  app.component(key, component);
}
app.mixin(helpers);

app.component(ExportExcelSheetVueJs);
app.use(ElementPlus);
app.use(VueAxios, axios);
app.use(router);
app.use(store);
app.mount("#app");
