export const sidebar = {
  namespaced: true,
  state: {
    isCollaspe: false,
  },
  mutations: {
    toggle(state) {
      state.isCollaspe = !state.isCollaspe;
    },
    close(state) {
      state.isCollaspe = true;
    },
    open(state) {
      state.isCollaspe = false;
    },
  },
  actions: {
    toggle({ commit }) {
      commit("toggle");
    },
    closeSideBar({ commit }) {
      commit("close");
    },
    openSideBar({ commit }) {
      commit("open");
    },
  },
};
