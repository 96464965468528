import moment from "moment";

export const displayDate = (date) => {
  if (!date) {
    return "";
  }
  return moment(date).format("DD MMM yyyy");
};
export const displayDateTime = (date) => {
  if (!date) {
    return "";
  }
  return moment(date).format("DD MMM yyyy hh:mm A");
};

export const displayUTCDateTime = (date) => {
  if (!date) {
    return "";
  }
  return moment.utc(date).format("DD MMM yyyy hh:mm A");
};

export const displayTime = (dateTime) => {
  if (!dateTime) {
    return "";
  }
  return moment(dateTime).format("hh:mm A");
};

export const convertSecondToTime = (seconds) => {
  if(!seconds) {
    return ''
  }
  const positiveSeconds = Math.abs(seconds);
  const minutes = Math.floor(positiveSeconds / 60);
  const remainingSeconds = positiveSeconds % 60;
  const roundedMinutes = remainingSeconds > 1 ? minutes + 1 : minutes;
  const duration = moment.duration(roundedMinutes, 'minutes');
  const time = moment().startOf('day').add(duration);
  const formattedTime = time.format('HH:mm');

  // Return empty string if time is '00:00'
  return formattedTime === '00:00' ? '' : formattedTime;
}
