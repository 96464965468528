<template>
  <div style="padding: 20px">
    <router-view/>
  </div>
</template>

<script>
export default {
  name: "AppMain"
}
</script>