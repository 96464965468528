<template>
  <div class="navbar rowBC reset-el-dropdown">
    <div class="row">
      <el-icon
        style="margin-right: 40px;"
        @click="toggle"
      >
        <fold />
      </el-icon>
      <p>{{$router.currentRoute.value.name}}</p>
    </div>
    <el-row>
      <!-- <p>{{username}}</p> -->
      <el-dropdown
        trigger="click"
        size="medium"
      >
        <div class="flex items-end">
          <el-icon
            class="user-avatar"
            v-if="profile_image_url == null"
            size="20px"
            color="#304156"
          ><user-filled /></el-icon>
          <img
            v-if="profile_image_url != null"
            :src="profile_image_url"
            alt="profile"
            class="w-10 h-10 rounded-full object-cover border-2 border-gray-400"
          >
          <CaretBottom style="width: 1em; height: 1em; margin-left: 4px; color:#304156;" />
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <router-link to="/profile">
              <el-dropdown-item>{{username}}</el-dropdown-item>
            </router-link>
            <el-dropdown-item
              divided
              @click="logOut"
            >Log out</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </el-row>
  </div>
</template>

<script>
import { CaretBottom } from "@element-plus/icons-vue";
import ProfileStorage from "@/storages/profileStorage";

export default {
  name: "Navbar",
  setup() {
    const profileStorage = new ProfileStorage();
    const { email, employee, profile_image_url } = profileStorage.read();
    var username = "";
    if (employee) {
      username = `${employee?.first_name} ${employee?.last_name}`;
    } else {
      username = email;
    }

    return { username, profile_image_url };
  },
  components: {
    CaretBottom,
  },
  computed: {
    isCollaspe() {
      return this.$store.state.sidebar.isCollaspe;
    },
  },
  methods: {
    toggle() {
      return this.$store.dispatch("sidebar/toggle");
    },
    logOut() {
      this.$logOut();
      this.$router.push({ name: "Login" });
    },
  },
};
</script>

<style lang="scss" scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
}
.row {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.rowBC {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px 0px 20px;
}
//logo
.avatar-wrapper {
  margin-top: 5px;
  position: relative;
  cursor: pointer;

  .user-avatar {
    cursor: pointer;
    width: 40px;
    height: 40px;
    border-radius: 10px;
  }

  .el-icon-caret-bottom {
    cursor: pointer;
    position: absolute;
    right: -20px;
    top: 25px;
    font-size: 12px;
  }
}

//center-title
.heardCenterTitle {
  text-align: center;
  position: absolute;
  top: 50%;
  left: 46%;
  font-weight: 600;
  font-size: 20px;
  transform: translate(-50%, -50%);
}

//drop-down
.right-menu {
  cursor: pointer;
  margin-right: 40px;
}
</style>