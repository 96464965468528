import ProfileStorage from "@/storages/profileStorage";

export const isAdmin = () => {
  const profileStorage = new ProfileStorage();
  const { is_admin } = profileStorage.read();
  return is_admin;
};

export const isEmployee = () => {
  const profileStorage = new ProfileStorage();
  const { is_employee } = profileStorage.read();
  return is_employee;
};

export const isApprover = () => {
  const profileStorage = new ProfileStorage();
  const { is_approver } = profileStorage.read();
  return is_approver;
};

export const isSuperApprover = () => {
  const profileStorage = new ProfileStorage();
  const { is_super_approver } = profileStorage.read();
  return is_super_approver;
};
