import { createStore } from "vuex";
import createPersistedState from "vuex-persistedstate";
import { sidebar } from "./modules/sidebar";

const store = createStore({
  modules: {
    sidebar,
  },
  plugins: [createPersistedState()],
});

export default store;
