export default class BaseStorage {
  read() {
    var value = localStorage.getItem(this.key());
    return JSON.parse(value);
  }

  write(value) {
    localStorage.setItem(this.key(), JSON.stringify(value));
  }

  delete() {
    localStorage.removeItem(this.key());
  }

  key() {
    throw Error("Missing storage key");
  }
}
