<template>
  <div
    :class="classObj"
    class="layout-wrapper"
  >
    <div class="sidebar-container">
      <SideBar />
    </div>
    <div class="main-container">
      <Navbar />
      <AppMain />
    </div>
    <div class="footer">
      2022 Powered by IT Department.
    </div>
  </div>
</template>

<script>
import SideBar from "../components/SideBar.vue";
import Navbar from "../components/Navbar.vue";
import AppMain from "../components/AppMain.vue";
import { onBeforeMount, onBeforeUnmount, onMounted } from "vue-demi";
import store from "@/store";

export default {
  name: "Layout",
  setup() {
    const { body } = document;
    const WIDTH = 992;
    const isMobile = () => {
      const rect = body.getBoundingClientRect();
      return rect.width - 1 < WIDTH;
    };

    const resizeHandler = () => {
      if (!document.hidden) {
        if (isMobile()) {
          store.dispatch("sidebar/closeSideBar");
        } else {
          store.dispatch("sidebar/openSideBar");
        }
      }
    };

    onBeforeMount(() => {
      window.addEventListener("resize", resizeHandler);
    });

    onMounted(() => {
      if (isMobile()) {
        store.dispatch("sidebar/closeSideBar");
      } else {
        store.dispatch("sidebar/openSideBar");
      }
    });

    onBeforeUnmount(() => {
      window.removeEventListener("resize", resizeHandler);
    });
  },
  components: {
    SideBar,
    Navbar,
    AppMain,
  },
  computed: {
    classObj() {
      return {
        closeSidebar: this.$store.state.sidebar.isCollaspe,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.sidebar-container {
  transition: width 0.28s;
  width: 250px !important;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  overflow: hidden;
  background: #304156;
  overflow-y: scroll;
}
.main-container {
  min-height: 100%;
  transition: 250px 0.28s;
  margin-left: 250px;
  position: relative;
  margin-bottom: 100px;
}
.footer {
  margin-left: 250px;
  background: #fff;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1002;
  overflow: hidden;
  text-align: center;
  padding-top: 10px;
  padding-bottom: 10px;
  color: gray;
  font-size: 12px;
}

.closeSidebar {
  .sidebar-container {
    display: flex;
    justify-items: center;
    width: 60px !important;
  }
  .main-container {
    margin-left: 60px !important;
  }
}

@media (max-width: 993) {
  .footer {
    margin-left: 60px;
    background: #fff;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 1002;
    overflow: hidden;
    text-align: center;
    padding-top: 10px;
    padding-bottom: 10px;
    color: gray;
    font-size: 12px;
  }
}
</style>
